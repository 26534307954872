import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Form, Formik } from 'formik';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { originShape } from './OriginShape';
import DateRange from './request_modal/DateRange';
import QuarterlyStatementDateDropdown from './request_modal/QuarterlyStatementDateDropdown';
import api from './request_modal/api';
import ReportDownloader from './request_modal/report_downloader';
import {
  reportNames,
  entityTypeLabels,
  entityTypes,
  originTypes,
} from 'react/member/components/dashboard/reports/Constants';
import { getEntityTypeOptions } from 'react/member/components/dashboard/reports/EntityTypeOptions';
import RadioDropdownCollectionsShape from 'react/member/components/dashboard/reports/RadioDropdownCollectionsShape';
import TrueLinkBanner from 'react/shared/components/true_link/lab/TrueLinkBanner';
import TrueLinkModal from 'react/shared/components/true_link/lab/TrueLinkModal';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import TrueLinkSelect from 'react/shared/components/true_link/main/form/TrueLinkSelect';
const useStyles = makeStyles({
  formActions: {
    alignItems: 'center',
    display: 'flex',
    marginTop: 10,
  },
});

export default function BulkReportRequestModal({ entityCollections, onClose, origin, report }) {
  const classes = useStyles();
  const today = new Date().toISOString().split('T')[0];
  const [quarterlyStatementDates, setQuarterlyStatementDates] = useState(
    origin.quarterlyStatementDates,
  );

  const entityTypeOptions = getEntityTypeOptions({ origin, report, entityCollections });

  // Default to selecting first entity type option, which will usually be Organization, sometimes with a targetSubset
  const initialSelectedOption = entityTypeOptions.length > 0 && entityTypeOptions[0];
  let initialValues = {
    selectedEntity: '',
    entityType: origin.type,
    entityId: !origin.id ? '' : origin.id,
    targetSubset: '',
    reportType: report.type,
    startDate: today,
    endDate: today,
    minDate: '',
    maxDate: today,
    quarterlyDate: quarterlyStatementDates ? quarterlyStatementDates[0] : '',
  };

  // If we're pre-populating the dropdown with an option then we need to override the form initial values:
  if (initialSelectedOption) {
    initialValues = {
      ...initialValues,
      selectedEntity: initialSelectedOption.value,
      entityType: initialSelectedOption.targetType,
      entityId:
        // For a multi-org user, the entityId will be blank to not restrict the report to a specific org
        initialSelectedOption.targetType === entityTypes.organization && origin.id ? origin.id : '',
      targetSubset: initialSelectedOption.targetSubset,
    };
  }

  // Constrain the date range to one year - don't allow an end date that is more than one year after the start date
  const validationSchema = Yup.object().shape({
    startDate: Yup.date()
      .test('start-before-end', 'Start date must be before end date', (value, { parent }) => {
        const { endDate } = parent;
        if (!value || !endDate) return true;
        return moment(value).isSameOrBefore(endDate);
      })
      .test('max-year-range', 'Date range cannot exceed one year', (value, { parent }) => {
        const { endDate, entityType } = parent;
        if (!value || !endDate) return true;
        if (entityType === entityTypes.trustBeneficiary) return true;
        return moment(endDate).diff(moment(value), 'years', true) <= 1;
      }),
    entityId: Yup.string().when('entityType', {
      is: (entityType) => entityType !== entityTypes.organization,
      then: () => Yup.string().required('Please make a selection'),
      otherwise: () => Yup.string().optional(),
    }),
  });

  const restrictDatesToReleasedStatementDates = [
    reportNames.customStatements,
    reportNames.disbursementsByCategory,
  ].includes(report.name);

  const getCustomStatementDates = (targetSubset = null) => {
    if (!restrictDatesToReleasedStatementDates) {
      return {
        maxDate: today,
        minDate: '',
        startDate: today,
      };
    }

    if (targetSubset === entityTypes.pooledTrust) {
      return {
        maxDate: origin.customStatementDates.pooledTrustMaxDate,
        minDate: origin.customStatementDates.pooledTrustMinDate,
        startDate: origin.customStatementDates.pooledTrustStartDate,
      };
    }

    if (targetSubset === entityTypes.standaloneTrust) {
      return {
        maxDate: origin.customStatementDates.individualTrustMaxDate,
        minDate: origin.customStatementDates.individualTrustMinDate,
        startDate: origin.customStatementDates.individualTrustStartDate,
      };
    }

    return {
      maxDate: origin.customStatementDates.maxDate,
      minDate: origin.customStatementDates.minDate,
      startDate: origin.customStatementDates.startDate,
    };
  };

  if (restrictDatesToReleasedStatementDates) {
    const targetSubset = initialSelectedOption?.targetSubset;
    const { maxDate, minDate, startDate } = getCustomStatementDates(targetSubset);
    initialValues = {
      ...initialValues,
      startDate,
      endDate: maxDate,
      minDate,
      maxDate,
    };
  }

  const downloader = new ReportDownloader();
  const popUpCloseHandler = () => {
    downloader.cancelRequest();
    onClose();
  };

  const onEntityTypeSelect = ({ selectedOption, setFieldValue, setValues, values }) => {
    const isOrgEntity = selectedOption.targetType === entityTypes.organization;
    const resetEntityId = isOrgEntity && origin.id ? origin.id : '';
    if (isOrgEntity) {
      setQuarterlyStatementDates(origin.quarterlyStatementDates);
      setFieldValue('quarterlyDate', origin.quarterlyStatementDates[0]);
    }

    const { maxDate, minDate, startDate } = getCustomStatementDates(selectedOption.targetSubset);
    const resetStartDate =
      minDate &&
      (moment(values.startDate).isBefore(minDate) || moment(values.startDate).isAfter(maxDate))
        ? startDate
        : values.startDate;

    const resetEndDate =
      maxDate &&
      (moment(values.endDate).isAfter(maxDate) ||
        moment(values.endDate).isBefore(minDate) ||
        moment(values.endDate).isBefore(resetStartDate))
        ? maxDate
        : values.endDate;

    setValues({
      ...values,
      selectedEntity: selectedOption.value,
      entityType: selectedOption.targetType,
      entityId: resetEntityId,
      targetSubset: selectedOption.targetSubset,
      startDate: resetStartDate,
      endDate: resetEndDate,
      minDate,
      maxDate,
    });
  };

  // When an entity is picked for the report, update the min/max calendar dates supported
  // for that entity. If the user has already selected dates, don't override them unless they
  // are outside of the available date range for this report.
  const onEntitySelect = ({ selectedEntity, currentValues, setFieldValue, setValues }) => {
    const { entityType } = currentValues;
    const currentStartDate = currentValues.startDate;
    const currentEndDate = currentValues.endDate;

    const entitySlug = selectedEntity?.id;

    if (report.datePickerFormat) {
      api.getEntityDateRange(entityType, entitySlug).then((response) => {
        const { startDate, minDate, maxDate } = response.data.availableCustomStatementDates;
        if (report.name === reportNames.quarterlyStatements) {
          setQuarterlyStatementDates(response.data.quarterlyStatementDates);
          setValues({
            ...currentValues,
            quarterlyDate: response.data.quarterlyStatementDates[0],
            entityId: entitySlug,
          });

          return;
        }
        let resetStartDate = currentStartDate;
        let resetEndDate = currentEndDate;
        let resetMinDate = '';
        let resetMaxDate = today;

        if (restrictDatesToReleasedStatementDates) {
          resetMinDate = minDate;
          resetMaxDate = maxDate;
          // Reset the start and end dates if they are outside of the entity's available date range
          if (
            !currentStartDate ||
            moment(currentStartDate).isBefore(minDate) ||
            moment(currentStartDate).isAfter(maxDate) ||
            moment(currentStartDate).isBefore(currentEndDate)
          ) {
            resetStartDate = startDate;
          }
          if (
            !currentEndDate ||
            moment(currentEndDate).isAfter(maxDate) ||
            moment(currentEndDate).isBefore(minDate) ||
            moment(currentEndDate).isBefore(resetStartDate)
          ) {
            resetEndDate = maxDate;
          }
        }

        setValues({
          ...currentValues,
          startDate: resetStartDate,
          endDate: resetEndDate,
          minDate: resetMinDate,
          maxDate: resetMaxDate,
          entityId: entitySlug,
        });
      });
    } else {
      setFieldValue('entityId', entitySlug);
    }
  };

  const doneHandler = ({ isSuccessful = false, isError = false, errorMessage = '' }) => {
    if (isSuccessful) {
      Truelink.flash('success', 'Report created! Downloading now.');
      onClose();
    } else if (isError) {
      Truelink.flash('error', `Error: ${errorMessage}`);
    }
  };

  return (
    <TrueLinkModal
      handleClose={popUpCloseHandler}
      scrollable={false}
      title={`Preparing "${report.name}" report`}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          downloader.requestReport(doneHandler, values, actions.setSubmitting);
        }}
        validationSchema={validationSchema}
      >
        {({ handleChange, values, setFieldValue, setValues, isSubmitting, isValid, errors }) => {
          const disableSubmit = isSubmitting || !isValid;
          return (
            <Form>
              {origin.type === originTypes.organization ? (
                <>
                  <p>Export data for:</p>
                  <TrueLinkSelect
                    ariaLabel="Export data for"
                    id="entity-type-select"
                    name="entityType"
                    onChange={(event) => {
                      const selectedOption = entityTypeOptions.find(
                        (opt) => opt.value === event.target.value,
                      );
                      onEntityTypeSelect({ selectedOption, setFieldValue, setValues, values });
                    }}
                    options={entityTypeOptions}
                    sx={{
                      marginBottom: 4,
                    }}
                    value={values.selectedEntity}
                  />
                </>
              ) : (
                <p>Export data for: {origin.name}</p>
              )}

              {values.entityType && entityCollections[values.entityType] && (
                <>
                  <Autocomplete
                    autoComplete
                    freeSolo={false}
                    fullWidth
                    getOptionLabel={(option) => option.label}
                    id="entityIdAutocomplete"
                    onChange={(event, newEntity) => {
                      onEntitySelect({
                        selectedEntity: newEntity,
                        currentValues: values,
                        setFieldValue,
                        setValues,
                      });
                    }}
                    options={entityCollections[values.entityType]}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`Search for a ${entityTypeLabels[values.entityType]}`}
                      />
                    )}
                    sx={{
                      marginBottom: 4,
                    }}
                    value={
                      entityCollections[values.entityType].find(
                        (option) => option.id === values.entityId,
                      ) || null
                    }
                  />
                </>
              )}
              {report.datePickerFormat === 'range' && (
                <DateRange
                  endDate={values.endDate}
                  maxDate={values.maxDate}
                  minDate={values.minDate}
                  startDate={values.startDate}
                />
              )}
              {report.datePickerFormat === 'quarter' && (
                <QuarterlyStatementDateDropdown
                  handleChange={handleChange}
                  latestStatementEndDate={origin.latestTrustStatementEndDate}
                  quarterlyDate={values.quarterlyDate}
                  quarterlyStatementDates={quarterlyStatementDates}
                />
              )}
              {(errors.startDate || errors.endDate) && (
                <Typography color="error">{errors.startDate || errors.endDate}</Typography>
              )}
              <div className={classes.formActions}>
                <TrueLinkButton
                  disabled={disableSubmit}
                  loading={isSubmitting}
                  type="submit"
                  variant="primary"
                >
                  {isSubmitting ? 'Downloading' : 'Download'}
                </TrueLinkButton>
              </div>
              {isSubmitting && (
                <Box sx={{ maxWidth: 'fit-content', marginTop: 3 }}>
                  <TrueLinkBanner
                    text={"Almost done! Please stay on this page - it won't take long."}
                    variant="info"
                  />
                </Box>
              )}

              <Typography variant="caption">
                If you need assistance pulling reports for your organization, please contact{' '}
                <a href="mailto:trustsupport@truelinkfinancial.com">
                  trustsupport@truelinkfinancial.com
                </a>
              </Typography>
            </Form>
          );
        }}
      </Formik>
    </TrueLinkModal>
  );
}

BulkReportRequestModal.propTypes = {
  entityCollections: RadioDropdownCollectionsShape.isRequired,
  onClose: PropTypes.func.isRequired,
  origin: originShape,
  report: PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    datePickerFormat: PropTypes.string,
    supportsSearchBy: PropTypes.shape({
      beneficiary: PropTypes.bool.isRequired,
      organization: PropTypes.bool.isRequired,
      portfolio: PropTypes.bool.isRequired,
      trust: PropTypes.bool.isRequired,
      trustType: PropTypes.string,
    }).isRequired,
  }).isRequired,
};
