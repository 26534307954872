import Clipboard from 'clipboard';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import TrueLinkIcon from './TrueLinkIcon';

export default function CopyIcon({ copyValue }) {
  const ref = useRef(null);
  useEffect(() => {
    const clip = new Clipboard(ref.current, {
      text: () => {
        if (copyValue === null) {
          return;
        }

        if (typeof copyValue === 'string') {
          return copyValue;
        }
        // If it's a React component ref, get its text content
        return copyValue.current.textContent;
      },
    });
    return () => clip.destroy();
  }, [copyValue]);

  return <TrueLinkIcon asButton icon="copy" ref={ref} size="small" style={{ fontSize: '8px' }} />;
}

CopyIcon.propTypes = {
  copyValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
